import {
  ICustomEmailContent,
  IEntity,
  IEntityAutoCollectExclusionMap,
  IEntityMap,
  IGroupAutoCollectExclusionMap,
  IGroupMap,
  IOwner,
  IPublicOwner,
  IPublicOwnerMap,
  IRole,
  ISelfBillBulkSubscription,
} from "src/app/core/interfaces";
import { convertTimestampsToDates } from "src/app/core/utils/helper-functions";
import { IOrganizationStateModel } from "./secure-pro-model.interface";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "@angular/fire/firestore";

export class OrganizationStateObject implements IOrganizationStateModel {
  id: string;
  name: string;
  orgPhone?: string | null;
  orgEmail?: string | null;
  groups: IGroupMap;
  entities?: IEntityMap;
  publicOwners?: IPublicOwnerMap;
  roles?: { [roleId: string]: IRole } | undefined;
  logoUrl?: string;
  stripeCustomerId?: string | null = null;
  selfBilled?: boolean | null = null;
  autoCollectOrgDefaultEnabled?: boolean | null = null;
  customEmailContent?: ICustomEmailContent | null = null;
  enterpriseFeaturesEnabled?: boolean | null = null;
  bulkPricingCheckoutType?: ISelfBillBulkSubscription | null = null;
  autoCollectEntityExclusions?: IEntityAutoCollectExclusionMap;
  autoCollectGroupExclusions?: IGroupAutoCollectExclusionMap;

  constructor(org: IOrganizationStateModel) {
    this.id = org.id;
    this.name = org.name;
    this.orgPhone = org.orgPhone;
    this.orgEmail = org.orgEmail;
    this.groups = org.groups ? convertTimestampsToDates(org.groups) : {};
    this.entities = convertTimestampsToDates(this.buildEntities(org));
    this.publicOwners = convertTimestampsToDates(org.publicOwners);
    this.roles = org.roles;
    this.logoUrl = org.logoUrl;
    this.stripeCustomerId = org.stripeCustomerId;
    this.selfBilled = org.selfBilled;
    this.autoCollectOrgDefaultEnabled = org.autoCollectOrgDefaultEnabled;
    this.customEmailContent = org.customEmailContent;
    this.enterpriseFeaturesEnabled = org.enterpriseFeaturesEnabled;
    this.bulkPricingCheckoutType = org?.bulkPricingCheckoutType;
    this.autoCollectEntityExclusions = org.autoCollectEntityExclusions;
    this.autoCollectGroupExclusions = org.autoCollectGroupExclusions;
  }

  get activeGroups() {
    const activeGroups = Object.keys(this.groups).reduce((acc, groupId) => {
      const group = this.groups[groupId];
      if (group.active) {
        acc[groupId] = group;
      }
      return acc;
    }, {} as IGroupMap);

    return activeGroups;
  }

  /**
   * @description Returns the list of entities in the organization.
   * It flattens the entities object into an array and adds the group information to each entity.
   */
  get entitiesList(): IEntity[] {
    return Object.entries(this.entities || {}).map(([id, entity]) => {
      const groupName = Object.entries(this.groups).find(
        ([groupId]) => groupId === entity.groupId
      )?.[1]?.name;
      return {
        ...entity,
        groupName: groupName || "",
        id,
      };
    });
  }

  get publicOwnersList(): IPublicOwner[] {
    return Object.entries(this.publicOwners || {}).map(([ownerId, owner]) => {
      const entities = Object.keys(owner.entities).map((entityId) => {
        return this.entitiesList.find((entity) => entity.id === entityId);
      });
      const ownerWithDates = convertTimestampsToDates(owner);
      return {
        ...ownerWithDates,
        id: ownerId,
        entityNames: Array.from(
          new Set(entities.map((e) => e?.legalName || ""))
        ).sort(),
        groupNames: Array.from(
          new Set(entities.map((e) => e?.groupName || ""))
        ).sort(),
      };
    });
  }

  async getPublicOwnersByEntityId(entityId: string): Promise<IPublicOwner[]> {
    try {
      const db = getFirestore();
      const ownersRef = collection(
        db,
        "organization",
        this.id,
        "publicOwnerList"
      );
      const ownersQuery = query(
        ownersRef,
        where(`entities.${entityId}`, "!=", null)
      );

      const ownersSnapshot = await getDocs(ownersQuery);

      const ownersList = ownersSnapshot.docs.map((doc) => {
        return {
          ...(doc.data() as IPublicOwner),
          id: doc.id,
        };
      });

      return ownersList;
    } catch (error) {
      console.error(
        `Error getting public owners by entity id (${entityId}):`,
        error
      );
      return [];
    }
  }

  private buildEntities(org: IOrganizationStateModel): IEntityMap {
    const entities = {} as IEntityMap;

    Object.keys(org.entities || {}).map(async (entityId) => {
      if (!org.entities) {
        return;
      }
      const entity = org.entities[entityId];
      if (!entity) return;
      entities[entityId] = entity;
    });
    return entities;
  }

  getEntityGroupId(entityId: string): string | undefined {
    return this.entitiesList.find((entity) => entity.id === entityId)?.groupId;
  }

  getUserGroups(uid: string): string[] {
    const userGroups = Object.keys(this.activeGroups).reduce((acc, groupId) => {
      const group = this.activeGroups[groupId];
      if (group.userIds.includes(uid)) {
        acc.push(groupId);
      }
      return acc;
    }, [] as string[]);

    return userGroups;
  }

  getOwners(): IOwner[] {
    const owners = this.entitiesList.flatMap((entity) =>
      entity?.owners ? Object.values(entity.owners) : []
    );
    return owners;
  }

  async fetchEntityById(entityId: string): Promise<IEntity | undefined> {
    const db = getFirestore();
    const entityRef = doc(db, "organization", this.id, "entityList", entityId);
    const entitySnapshot = await getDoc(entityRef);
    const entity = entitySnapshot.data() as IEntity;
    return convertTimestampsToDates(entity);
  }

  async fetchEntityListByOwnerId(ownerId: string | null): Promise<IEntity[]> {
    const db = getFirestore();
    const entityListRef = collection(db, "organization", this.id, "entityList");
    const entityListQuery = query(
      entityListRef,
      where(`owners.${ownerId}`, "!=", null)
    );
    const entityListSnapshot = await getDocs(entityListQuery);

    const entities = entityListSnapshot.docs.map((doc) => {
      return doc.data() as IEntity;
    });
    return entities;
  }

  async fetchEntityOwner(ownerId: string | null): Promise<IOwner | undefined> {
    const entityList = await this.fetchEntityListByOwnerId(ownerId);

    const owners = entityList.flatMap((entity) =>
      entity?.owners ? Object.values(entity.owners) : []
    );

    const owner = owners.find((owner) => owner.id === ownerId) as IOwner;

    return convertTimestampsToDates(owner);
  }

  async fetchPublicOwner(
    ownerId: string | null
  ): Promise<IPublicOwner | undefined> {
    if (!ownerId) {
      return undefined;
    }
    const db = getFirestore();
    const ownersRef = doc(
      db,
      "organization",
      this.id,
      "publicOwnerList",
      ownerId
    );

    const ownersSnapshot = await getDoc(ownersRef);
    const owner = ownersSnapshot.data() as IOwner;
    return convertTimestampsToDates(owner);
  }
}
