import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  IAddressForm,
  IBaseEntityForm,
  IEntityDetails,
  IGroupMap,
} from "../interfaces";
import { FormValidationService } from "../services/form-validation.service";
import { OrganizationStateObject } from "src/app/state/secure-pro-state/organization-model";

const entityValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const formGroup = control as FormGroup<IBaseEntityForm>;

    // Make sure that the other tribe of formation is required if the tribe of formation is 'Other'
    const otherTribeOfFormationCtrl = formGroup.controls.otherTribeOfFormation;
    const tribeOfFormationCtrl = formGroup.controls.tribeOfFormation;
    if (tribeOfFormationCtrl.value === "Other") {
      otherTribeOfFormationCtrl.addValidators([Validators.required]);
      otherTribeOfFormationCtrl.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }

    return null;
  };
};

/**
 * @description generates a blank base entity form.
 */
export const generateBlankEntityForm = () =>
  new FormGroup<IBaseEntityForm>(
    {
      legalName: new FormControl<string | null>(null, [
        Validators.required,
        FormValidationService.notOnlyWhitespaceValidator(),
      ]),
      tradeNames: new FormArray([new FormControl<string | null>(null)]),
      formationDate: new FormControl<Date | null>(null),
      isFormedAfter2024: new FormControl<boolean | null>(null, [Validators.required]),
      address: new FormGroup<IAddressForm>(
        {
          streetLine1: new FormControl("", [
            Validators.required,
            FormValidationService.notOnlyWhitespaceValidator(),
          ]),
          streetLine2: new FormControl("", [
            FormValidationService.notOnlyWhitespaceValidator(),
          ]),
          city: new FormControl("", [
            Validators.required,
            FormValidationService.notOnlyWhitespaceValidator(),
          ]),
          state: new FormControl("", [Validators.required]),
          zip: new FormControl("", [Validators.required]),
          country: new FormControl("United States", [Validators.required]),
        },
        [Validators.required]
      ),
      foreignIdJurisdiction: new FormControl<string | null>({
        value: null,
        disabled: true,
      }),
      jurisdictionOfFormation: new FormControl<string | null>(null, [
        Validators.required,
      ]),
      stateOfFormation: new FormControl<string | null>(null, [
        Validators.required,
      ]),
      tribeOfFormation: new FormControl<string | null>(null),
      otherTribeOfFormation: new FormControl<string | null>(null),
      taxId: new FormControl<string | null>(null, [
        FormValidationService.taxIdValidator(),
        Validators.required,
      ]),
      taxIdType: new FormControl<string | null>(null, [Validators.required]),
      // foreignPooledInvestmentVehicle: new FormControl<boolean | null>(null, [
      //   Validators.required,
      // ]),
    },
    { validators: [entityValidator()] }
  );

export interface IEntityViolations {
  valid: boolean;
  reportingCompanyViolations: string[] | null;
  ownersViolations: {
    [ownerId: string]: string[] | null;
  };
}

export const getDefaultEntityDetails = (orgId: string): IEntityDetails => {
  return {
    auditLog: [],
    secureFileTransferComplete: false,
    orgId,
    userIds: [],
    filings: [],
    jwtToken: "",
    ownerIds: [],
  };
};

export const getExistingGroup = (
  existingGroupId: string | undefined,
  newGroupId: string,
  org: OrganizationStateObject,
  entityId: string
): IGroupMap => {
  let existingGroup: IGroupMap = {};
  if (existingGroupId && newGroupId !== existingGroupId) {
    existingGroup = {
      [existingGroupId]: {
        ...org.groups[existingGroupId],
        entities: org.groups[existingGroupId].entities.filter(
          (entity) => entity.id !== entityId
        ),
      },
    };
  }
  return existingGroup;
};

export const getNewGroup = (
  newGroupId: string,
  entityId: string,
  org: OrganizationStateObject,
  legalName: string
) => {
  return {
    [newGroupId]: {
      ...org.groups[newGroupId],
      entities: [
        ...org.groups[newGroupId].entities.filter(
          (entity) => entity.id !== entityId
        ),
        {
          name: legalName,
          id: entityId,
        },
      ],
    },
  };
};
