import { inject, Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  Firestore,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import {
  IAuditLog,
  IEntityAutoCollectExclusionMap,
  IGroupAutoCollectExclusionMap,
  ICheckIfEmailInUseRequest,
  ICheckIfEmailInUseResponse,
  IOwner,
  IPublicOwnerMap,
  ISendInstantlyTransferEmailRequest
} from "src/app/core/interfaces";
import { BoirFilingsService } from "src/app/core/services/boir-filings.service";
import { CommonApisService } from "src/app/core/services/common-apis.service";
import { getExistingGroup, getNewGroup, } from "src/app/core/utils/entity-helpers";
import { convertDatesToTimestamps } from "src/app/core/utils/helper-functions";
import { defaultQuickStartState } from "src/app/core/utils/quick-start-helpers";
import { v4 as uuidV4 } from "uuid";
import {
  IEntity,
  IEntityDetails,
  IEntityMap,
  IGroup,
  IGroupMap,
  IHttpsCallableResponse,
  IMirrorOwnerRequest,
  IMoveEntityGroupRequest,
  IOrganization,
  IPublicOwner,
  IRevokeSecureFileInviteRequest,
  ISendSecureFileTransferEmailRequest,
  IUpsertProEntityRequest,
  IUser,
  IUserInviteRequest,
  IUserInviteResponse,
  rolesToPermissions,
} from "../../core/interfaces";
import { ErrorHandlerService } from "../../core/services/firebase-error-handler.service";
import { updateGroupUsers } from "../../core/utils/common-db-operations";
import { EntityDetailsStateModel } from "../entity-details-state/entity-details-model.interface";
import { EntityDetails } from "../entity-details-state/entity-details.actions";
import { UserStateModel } from "../user-state/user-model.interface";
import { OrganizationStateObject } from "./organization-model";
import { defaultSecureProState, SecureProStateModel, } from "./secure-pro-model.interface";
import { Entity, Group, Organization, OrgUser, Owner, } from "./secure-pro.actions";
import { EntityService } from "src/app/core/services/entity-service.service";
import { Timestamp } from "firebase/firestore";
import { User } from "../user-state/user.actions";
import { Collection } from "../collection-state/collection.actions";
import { Subscription } from "rxjs";
import { collectionChanges } from "rxfire/firestore";
import { Router } from "@angular/router";

@State<SecureProStateModel>({
  name: "securePro",
  defaults: { ...defaultSecureProState },
})
@Injectable()
export class SecureProState {
  private commonApis = inject(CommonApisService);
  private firestore = inject(Firestore);
  private auth = inject(Auth);
  private functions = inject(Functions);
  private errorHandler = inject(ErrorHandlerService);
  private snackbar = inject(MatSnackBar);
  private store = inject(Store);
  private boirFilingsService = inject(BoirFilingsService);
  private entityService = inject(EntityService);
  private router: Router = inject(Router);

  private entitiesCollectionSubscription!: Subscription;

  @Action(OrgUser.GetAllUsers)
  async getAllUsers(ctx: StateContext<SecureProStateModel>) {
    const org = ctx.getState().org;
    if (!org) {
      throw new Error("Organization does not exist!");
    }
    const orgId = org.id;

    try {
      // Use the organization ID to get all of the users in the organization
      // from firestore
      const userCollection = collection(this.firestore, "user");
      const userQuery = query(userCollection, where("orgId", "==", orgId));
      const userSnapshot = await getDocs(userQuery);
      const userData = userSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as UserStateModel;
      });
      // Patch state
      ctx.patchState({ orgUsers: userData });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(OrgUser.Update)
  async updateUser(
    ctx: StateContext<SecureProStateModel>,
    action: OrgUser.Update
  ) {
    const { updatedUser } = action;
    const org = ctx.getState().org;

    if (updatedUser.roles.includes("SuperAdmin")) {
      this.snackbar.open(
        "You cannot add another superadmin at this time.",
        "Dismiss",
        {
          duration: 5000,
        }
      );
      return;
    }

    // Now we can update the user
    const userModel: IUser = {
      ...updatedUser,
      orgId: org.id,
    };
    const userDoc = doc(this.firestore, "user", updatedUser.id);
    await setDoc(userDoc, <IUser>{
      ...userModel,
    });

    // Patch state
    const orgUsers = ctx.getState().orgUsers;
    const updatedOrgUsers: UserStateModel[] = orgUsers.map((user) => {
      if (user.id === updatedUser.id) {
        return {
          ...userModel,
          id: user.id,
        };
      }
      return user;
    });

    ctx.patchState({
      orgUsers: updatedOrgUsers,
    });
  }

  @Action(OrgUser.UpdateGroupMembership)
  async updateGroupMembership(
    ctx: StateContext<SecureProStateModel>,
    action: OrgUser.UpdateGroupMembership
  ) {
    const { userId, groupIds } = action;
    const stateGroups = ctx.getState().org.groups;
    // Update the state groups by adding the user id to the groups that the user
    // is a member of
    const updatedGroups = Object.keys(stateGroups).reduce((acc, groupId) => {
      const group = stateGroups[groupId];
      if (groupIds.includes(groupId)) {
        acc[groupId] = {
          ...group,
          userIds: Array.from(new Set([...group.userIds, userId])),
        };
      } else {
        // If the updated user is not a member of the group, we need to remove
        // the user id from the group if it exists
        acc[groupId] = {
          ...group,
          userIds: group.userIds.filter((id) => id !== userId),
        };
      }
      return acc;
    }, {} as IGroupMap);

    const org = ctx.getState().org;
    if (!org) {
      throw new Error("Organization does not exist!");
    }

    const updatedOrg: IOrganization = {
      ...org,
      groups: {
        ...updatedGroups,
      },
    };

    await updateGroupUsers(org.id, updatedGroups, this.firestore);

    // Update the org with the updated groups
    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, {
      groups: updatedOrg.groups,
    });

    ctx.patchState({
      org: new OrganizationStateObject({
        ...updatedOrg,
        id: org.id,
      }),
    });
  }

  @Action(OrgUser.Delete)
  async deleteUser(
    ctx: StateContext<SecureProStateModel>,
    action: OrgUser.Delete
  ) {
    try {
      const userId = action.uid;
      const role = action.role;

      // Verify that the user isn't deleting himself
      if (this.auth.currentUser?.uid === userId) {
        this.snackbar.open("You cannot delete yourself!", "Dismiss", {
          duration: 5000,
        });
        return;
      }

      if (role === "SuperAdmin") {
        this.snackbar.open("You cannot delete the super admin!", "Dismiss", {
          duration: 5000,
        });
        return;
      }

      // Delete the user from the user collection
      const userDoc = doc(this.firestore, "user", userId);
      await deleteDoc(userDoc);

      // Delete the user from any groups that he's a member of
      const org = ctx.getState().org;
      const orgGroups = org.groups;
      const updatedGroups = Object.keys(orgGroups).reduce((acc, groupId) => {
        const group = orgGroups[groupId];
        if (group.userIds.includes(userId)) {
          acc[groupId] = {
            ...group,
            userIds: group.userIds.filter((id) => id !== userId),
          };
        } else {
          acc[groupId] = group;
        }
        return acc;
      }, {} as IGroupMap);

      // Update the org with the updated groups
      const updatedOrg: IOrganization = {
        ...org,
        groups: {
          ...orgGroups,
          ...updatedGroups,
        },
      };

      await updateGroupUsers(org.id, updatedGroups, this.firestore);

      // Patch state
      const orgUsers = ctx.getState().orgUsers;
      const updatedOrgUsers: UserStateModel[] = orgUsers.filter((user) => {
        return user.id !== userId;
      });

      ctx.patchState({
        org: new OrganizationStateObject({
          ...updatedOrg,
          id: org.id,
        }),
        orgUsers: updatedOrgUsers,
      });

      this.snackbar.open("User successfully deleted!", "Dismiss", {
        duration: 3000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(OrgUser.Reset)
  async resetOrgUser(ctx: StateContext<SecureProStateModel>) {
    ctx.setState({
      ...defaultSecureProState,
    });
  }

  @Action(OrgUser.InviteUser)
  async inviteUser(
    ctx: StateContext<SecureProStateModel>,
    action: OrgUser.InviteUser
  ) {
    const { email, role, groupIds } = action;
    const org = ctx.getState().org;

    // First, create the temp user doc in firestore
    try {
      if (role === "SuperAdmin") {
        this.snackbar.open(
          "You cannot add another superadmin at this time.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        return;
      }

      const checkIfEmailInUseCallable = httpsCallable<
        ICheckIfEmailInUseRequest,
        ICheckIfEmailInUseResponse
      >(this.functions, "checkIfEmailInUse");

      const request: ICheckIfEmailInUseRequest = { email };

      const checkIfEmailInUseResponse = await checkIfEmailInUseCallable(request);
      const { success, message, isEmailInUse } = checkIfEmailInUseResponse.data;

      if (!success) {
        throw new Error(message)
      }

      if (isEmailInUse) {
        this.snackbar.open(
          "A user with that email already exists. Please use a different email.",
          "Dismiss",
          {
            duration: 5000,
          }
        );
        return;
      }

      const userCollection = collection(this.firestore, "user");
      const userDoc = doc(userCollection, uuidV4());
      const userInvite: IUser = {
        email,
        name: "(Pending)",
        consentFirstName: null,
        consentLastName: null,
        consentEmail: null,
        orgId: org.id,
        permissions: rolesToPermissions[role],
        roles: [role],
        skipTwoFactor: false,
        userType: "secure-pro",
        stripeCustomerId: null,
        quickStartState: defaultQuickStartState(),
        proAssociation: null,
      };
      await setDoc(userDoc, userInvite);

      // add the user id to all of the groups
      const updatedGroups = groupIds.reduce((acc, groupId) => {
        const group = org.groups[groupId];
        acc[groupId] = {
          ...group,
          userIds: Array.from(new Set([...group.userIds, userDoc.id])),
        };
        return acc;
      }, {} as IGroupMap);

      const updatedOrg: IOrganization = {
        ...org,
        groups: {
          ...org.groups,
          ...updatedGroups,
        },
      };

      await updateGroupUsers(org.id, updatedGroups, this.firestore);

      ctx.setState(
        patch<SecureProStateModel>({
          org: new OrganizationStateObject({
            ...updatedOrg,
            id: org.id,
          }),
        })
      );

      const inviteUserCallable = httpsCallable<
        IUserInviteRequest,
        IUserInviteResponse
      >(this.functions, "sendUserInvite");

      const inviteResponse = await inviteUserCallable(<IUserInviteRequest>{
        to: email,
        orgId: org.id,
        orgName: org.name,
        tempUserId: userDoc.id,
        senderEmail: this.auth.currentUser?.email,
      });
      if (!inviteResponse.data.success) {
        throw new Error(inviteResponse.data.message);
      }
      this.snackbar.open(`Successfully invited ${email}!`, "Dismiss", {
        duration: 5000,
      });
      ctx.dispatch(new OrgUser.GetAllUsers());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Organization.UpdateLogo)
  async updateOrganizationLogo(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.UpdateLogo
  ) {
    const org = ctx.getState().org;
    const updatedOrg = {
      ...org,
      logoUrl: action.logoUrl,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, { logoUrl: action.logoUrl });
  }

  @Action(Organization.UpdateName)
  async setOrganizationName(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.UpdateName
  ) {
    const org = ctx.getState().org;
    const updatedOrg = {
      ...org,
      name: action.name,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, { name: action.name });

    this.snackbar.open("Organization name updated!", "Dismiss", {
      duration: 5000,
    });
  }

  @Action(Organization.UpdateContactInformation)
  async updateOrganizationContacts(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.UpdateContactInformation
  ) {
    const org = ctx.getState().org;

    const shouldUpdateContactInformation =
      action.orgPhone !== org.orgPhone || action.orgEmail !== org.orgEmail;

    if (!shouldUpdateContactInformation) return;

    const updatedOrg = {
      ...org,
      orgPhone: action.orgPhone,
      orgEmail: action.orgEmail,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, {
      orgPhone: action.orgPhone,
      orgEmail: action.orgEmail,
    });

    this.snackbar.open("Organization contact information updated!", "Dismiss", {
      duration: 5000,
    });
  }

  @Action(Organization.UpdateEnterpriseFeaturesEnabled)
  async updateOrganizationEnterpriseFeaturesEnabled(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.UpdateEnterpriseFeaturesEnabled
  ) {
    const org = ctx.getState().org;
    const updatedOrg = {
      ...org,
      enterpriseFeaturesEnabled: action.enterpriseFeaturesEnabled,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, {
      enterpriseFeaturesEnabled: action.enterpriseFeaturesEnabled,
    });

    this.snackbar.open("Organization enterprise features enabled!", "Dismiss", {
      duration: 5000,
    });
  }

  @Action(Organization.Reset)
  async resetOrganization(ctx: StateContext<SecureProStateModel>) {
    ctx.setState({
      ...defaultSecureProState,
    });
  }

  @Action(Organization.Get)
  async getOrganization(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.Get
  ) {
    const orgId = action.orgId;
    const orgDoc = doc(this.firestore, "organization", orgId);
    const orgSnapshot = await getDoc(orgDoc);
    if (orgSnapshot.exists()) {
      const orgData = orgSnapshot.data() as IOrganization;
      const groupDocs = await getDocs(
        collection(this.firestore, "organization", orgId, "groups")
      );
      const groups = groupDocs.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data() as IGroupMap[string];
        return acc;
      }, {} as IGroupMap);

      const fullOrg = {
        ...orgData,
        groups,
      };

      const orgState = new OrganizationStateObject({
        ...fullOrg,
        id: orgSnapshot.id,
      });
      // Patch state
      ctx.patchState({ org: orgState });

      // Dispatch another action to get the entity data
      ctx.dispatch(new OrgUser.GetAllUsers());
      ctx.dispatch(new Entity.GetList(Object.keys(orgState.groups)));
      ctx.dispatch(new Owner.GetPublicOwners());
    } else {
      // Handle case where org does not exist
      console.error("Organization does not exist!");
    }
  }

  @Action(Organization.SetGlobalSelfBill)
  async setOrganizationGlobalSelfBill(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.SetGlobalSelfBill
  ) {
    const org = ctx.getState().org;
    const updatedOrg = {
      ...org,
      selfBilled: action.selfBilled,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, { selfBilled: action.selfBilled });

    this.snackbar.open(
      "Organization global self bill set to " + action.selfBilled,
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }

  @Action(Organization.SetDefaultAutoCollect)
  async setOrganizationDefaultAutoCollect(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.SetDefaultAutoCollect
  ) {
    const org = ctx.getState().org;
    const updatedOrg = {
      ...org,
      autoCollectOrgDefaultEnabled: action.defaultAutoCollect,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, {
      autoCollectOrgDefaultEnabled: action.defaultAutoCollect,
    });

    this.snackbar.open(
      "Organization default Auto Collect set to " + action.defaultAutoCollect,
      "Dismiss",
      {
        duration: 5000,
      }
    );
  }

  @Action(Organization.UpdateCustomEmailContent)
  async updateCustomEmailContent(
    ctx: StateContext<SecureProStateModel>,
    action: Organization.UpdateCustomEmailContent
  ) {
    const org = ctx.getState().org;
    if (!org.enterpriseFeaturesEnabled) {
      this.snackbar.open(
        "Custom email content is an Enterprise Feature, Enterprise features are not enabled for this organization.",
        "Dismiss",
        {
          duration: 5000,
        }
      );
      return;
    }
    const updatedOrg = {
      ...org,
      customEmailContent: action.content,
    };
    ctx.patchState({
      org: new OrganizationStateObject(updatedOrg),
    });

    const orgDoc = doc(this.firestore, "organization", org.id);
    await updateDoc(orgDoc, { customEmailContent: action.content });

    this.snackbar.open("Organization custom email content updated", "Dismiss", {
      duration: 5000,
    });
  }
  /**
   *
   * Load all groups in the organization
   */
  @Action(Group.LoadAll)
  async loadAllGroups(ctx: StateContext<SecureProStateModel>) {
    const org = ctx.getState().org;
    if (!org) {
      throw new Error("Organization does not exist!");
    }

    const groupCollection = collection(
      this.firestore,
      "organization",
      org.id,
      "groups"
    );
    const groupSnapshot = await getDocs(groupCollection);
    const groups = groupSnapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data() as IGroup;
      return acc;
    }, {} as IGroupMap);

    ctx.patchState({
      org: new OrganizationStateObject({
        ...org,
        groups,
      }),
    });
  }
  /**
   *
   * Create or update a group in the organization
   */
  @Action(Group.Upsert)
  async upsertGroup(
    ctx: StateContext<SecureProStateModel>,
    action: Group.Upsert
  ) {
    const { groupId, group } = action;
    if (!group.name) throw new Error("Group name is required.");
    const org = ctx.getState().org;

    const groupBeforeUpdate: IGroup | undefined = org.groups[groupId];

    // When updating the group, we need to make sure that we move all of the entity
    // ids from the old group to the new group.
    // To do this, we can search through all of the groups in the organization
    // except the group we are updating, and if we find an entity id that matches
    // one in the updated group, we can remove it from the old group.
    const groupIds = Object.keys(org.groups);
    const otherGroupIds = groupIds.filter((id) => id !== groupId);

    const groupDoc = doc(
      this.firestore,
      "organization",
      org.id,
      "groups",
      groupId
    );

    let shouldUpdateGroup =
      !groupBeforeUpdate ||
      groupBeforeUpdate.entities.length !== group.entities.length ||
      groupBeforeUpdate.userIds.length !== group.userIds.length ||
      groupBeforeUpdate.name !== group.name;

    const groupEntitiesIds = group.entities.map((entity) => entity.id);
    groupBeforeUpdate?.entities.forEach((entity) => {
      if (!groupEntitiesIds.includes(entity.id)) shouldUpdateGroup = true;
    });

    groupBeforeUpdate?.userIds.forEach((userId) => {
      if (!group.userIds.includes(userId)) shouldUpdateGroup = true;
    });

    if (!shouldUpdateGroup) throw new Error("No changes to group.");

    await setDoc(
      groupDoc,
      {
        ...group,
      },
      { merge: true }
    );

    const moveEntityGroupCallable = httpsCallable<
      IMoveEntityGroupRequest,
      IHttpsCallableResponse
    >(this.functions, "moveEntityGroup");

    if (groupBeforeUpdate?.name !== group?.name) {
      const updateGroupNameInEntities = group.entities.map(
        async (publicEntity) => {
          const entityDoc = doc(
            this.firestore,
            "organization",
            org.id,
            "entityList",
            publicEntity.id
          );

          updateDoc(entityDoc, {
            groupName: group.name,
          });

          const entitySnapshot = await getDoc(entityDoc);
          const entity = entitySnapshot.data() as IEntity;

          if (entity.handedOff) {
            const secureFileDoc = doc(
              this.firestore,
              "secureFileEntities",
              entity.id
            );

            updateDoc(secureFileDoc, {
              groupName: group.name,
            });
          }
        }
      );

      await Promise.all(updateGroupNameInEntities);
    }

    // We're going to call the cloud function to update the entity groups
    for (const fromGroupId of otherGroupIds) {
      // Find each entity id that is in the updated group and isn't in
      // the group before the update
      const entitiesToRemoveFromOtherGroups = group.entities.filter(
        (transferredEntities) => {
          const transferredEntitiesNotInGroupBeforeUpdate =
            !groupBeforeUpdate?.entities
              .map((e) => e.id)
              .includes(transferredEntities.id);

          const fromGroupContainsTransferredEntity = org.groups[
            fromGroupId
          ].entities
            .map((e) => e.id)
            .includes(transferredEntities.id);

          return (
            transferredEntitiesNotInGroupBeforeUpdate &&
            fromGroupContainsTransferredEntity
          );
        }
      );

      for (const entity of entitiesToRemoveFromOtherGroups) {
        const moveEntityGroupData: IMoveEntityGroupRequest = {
          orgId: org.id,
          entityId: entity.id,
          entityLegalName: entity.name,
          fromGroupId: fromGroupId,
          toGroupId: groupId,
        };

        const moveEntityGroupResponse = await moveEntityGroupCallable(
          moveEntityGroupData
        );

        if (!moveEntityGroupResponse.data.success) {
          throw new Error(moveEntityGroupResponse.data.message);
        }
      }
    }

    const entityIdsToRemoveFromOtherGroups = group.entities.map(
      (entity) => entity.id
    );
    otherGroupIds.forEach((id) => {
      org.groups[id].entities = org.groups[id].entities.filter(
        (entity) => !entityIdsToRemoveFromOtherGroups.includes(entity.id)
      );
    });

    const updatedOrg: IOrganization = {
      ...org,
      groups: {
        ...org.groups,
        [groupId]: group,
      },
    };

    ctx.setState(
      patch<SecureProStateModel>({
        org: new OrganizationStateObject({
          ...updatedOrg,
          id: org.id,
        }),
      })
    );

    // Refresh the entity list
    ctx.dispatch(new Entity.GetList(Object.keys(updatedOrg.groups || {})));
  }

  /**
   * Deletes a group from the organization
   */
  @Action(Group.Delete)
  async deleteGroup(
    ctx: StateContext<SecureProStateModel>,
    action: Group.Delete
  ) {
    const { groupId } = action;
    const org = ctx.getState().org;
    if (!org) {
      throw new Error("Organization does not exist!");
    }

    // Can't delete the group if it's the default group
    if (org.groups[groupId].default) {
      this.snackbar.open("Cannot delete default group.", "Dismiss", {
        duration: 5000,
      });
      return;
    }

    // Can't delete the group if there are entities in it
    if (org.groups[groupId].entities.length > 0) {
      this.snackbar.open(
        "Cannot delete group with entities in it. Please transfer entities to another group first.",
        "Dismiss",
        {
          duration: 5000,
        }
      );
      return;
    }

    const updatedOrg: IOrganization = {
      ...org,
      groups: {
        ...org.groups,
      },
    };
    if (updatedOrg.groups && groupId in updatedOrg.groups) {
      delete updatedOrg.groups[groupId];
    }

    const groupDoc = doc(
      this.firestore,
      "organization",
      org.id,
      "groups",
      groupId
    );
    await deleteDoc(groupDoc);

    this.snackbar.open("Group successfully deleted!", "Dismiss", {
      duration: 3000,
    });
    ctx.patchState({
      org: new OrganizationStateObject({ ...updatedOrg, id: org.id }),
    });
  }

  @Action(Group.LoadAutoCollectExclusions)
  async loadGroupAutoCollectExclusions(
    ctx: StateContext<SecureProStateModel>
  ) {
    try {
      const org = ctx.getState().org;
      if (!org) {
        throw new Error("Organization does not exist!");
      }

      const groupAutoCollectExclusionsRef = collection(
        this.firestore,
        "organization",
        org.id,
        "autoCollectGroupExclusions"
      );

      const groupAutoCollectExclusionsSnapshot = await getDocs(
        groupAutoCollectExclusionsRef
      );

      const groupAutoCollectExclusions = groupAutoCollectExclusionsSnapshot.docs.reduce(
        (acc, doc) => {
          acc[doc.id] = doc.data() as { id: string };
          return acc;
        },
        {} as IGroupAutoCollectExclusionMap
      );

      ctx.dispatch(new Group.LoadAutoCollectExclusionsSuccess(groupAutoCollectExclusions));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Group.LoadAutoCollectExclusionsSuccess)
  async loadGroupAutoCollectExclusionsSuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Group.LoadAutoCollectExclusionsSuccess
  ) {
    try {
      const { groupAutoCollectExclusionMap } = action;
      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          autoCollectGroupExclusions: groupAutoCollectExclusionMap,
        }),
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Group.AutoCollectExclusion)
  async autoCollectExclusion(
    ctx: StateContext<SecureProStateModel>,
    action: Group.AutoCollectExclusion
  ) {
    try {
      const { groupUpdate } = action;

      const org = ctx.getState().org;

      const batch = writeBatch(this.firestore);

      const groupAutoCollectExclusionsRef = collection(
        this.firestore,
        "organization",
        org.id,
        "autoCollectGroupExclusions"
      );

      if (groupUpdate.autoCollectExcluded) {
        const groupExclusionDoc = doc(groupAutoCollectExclusionsRef, groupUpdate.id);
        batch.set(groupExclusionDoc, { id: groupUpdate.id });
      } else {
        const groupExclusionDoc = doc(groupAutoCollectExclusionsRef, groupUpdate.id);
        batch.delete(groupExclusionDoc);
      }

      await batch.commit();

      ctx.dispatch(new Group.AutoCollectExclusionSuccess(groupUpdate));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Group.AutoCollectExclusionSuccess)
  async autoCollectExclusionSuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Group.AutoCollectExclusionSuccess
  ) {
    try {
      const { groupUpdate } = action;
      const groupAutoCollectExclusionMap = ctx.getState().org.autoCollectGroupExclusions || {};
      const updatedGroupAutoCollectExclusionMap = groupUpdate.autoCollectExcluded
      ? {
        ...groupAutoCollectExclusionMap,
        [groupUpdate.id]: { id: groupUpdate.id },
      }
      : Object.keys(groupAutoCollectExclusionMap).reduce((acc, id) => {
        if (id !== groupUpdate.id) {
          acc[id] = groupAutoCollectExclusionMap[id];
        }
        return acc;
      }, {} as IGroupAutoCollectExclusionMap);
      
      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          autoCollectGroupExclusions: updatedGroupAutoCollectExclusionMap,
        }),
      });

      this.snackbar.open("Group auto collect exclusion updated successfully!", "Dismiss", {
        duration: 5000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////// ENTITY ACTIONS //////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Action(Entity.GetEntity)
  async getEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.GetEntity
  ) {
    try {
      const { entityId } = action;
      const org = ctx.getState().org;
      if (!org) {
        throw new Error("Organization does not exist!");
      }

      const entity = await org.fetchEntityById(entityId);
      if (!entity) {
        this.snackbar.open(
          "Entity does not exist!", "Dismiss",
          { duration: 5000 }
        );
        this.router.navigate(["/entities"]);
        return;
      }

      ctx.dispatch(new Entity.GetEntitySuccess(entity));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.GetEntitySuccess)
  async getEntitySuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.GetEntitySuccess
  ) {
    try {
      const { entity } = action;
      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          entities: {
            ...org.entities,
            [entity.id]: entity,
          },
        }),
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.GetList)
  async getEntityList(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.GetList
  ) {
    try {
      const { groupIds } = action;
      const org = ctx.getState().org;

      const entitiesCollectionRef = collection(
        this.firestore,
        "organization",
        org.id,
        "entityList"
      );

      const entitiesSnapshot = await getDocs(entitiesCollectionRef);

      const entities = entitiesSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data() as IEntity;
        return acc;
      }, {} as IEntityMap);

      const filteredEntities = Object.keys(entities).reduce((acc, entityId) => {
        const entity = entities[entityId];
        if (groupIds.includes(entity.groupId)) {
          acc[entityId] = entity;
        }
        return acc;
      }, {} as IEntityMap);

      if (!this.entitiesCollectionSubscription) {
        this.entitiesCollectionSubscription = collectionChanges(
          entitiesCollectionRef,
          {events: ['added', 'removed']}
        ).subscribe(() => {
            ctx.dispatch(new Entity.GetList(groupIds));
          })
      }

      ctx.dispatch(new Entity.GetListSuccess(filteredEntities));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.GetListSuccess)
  async getEntityListSuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.GetListSuccess
  ) {
    try {
      const { entities } = action;

      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          entities,
        }),
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Create or update an entity in the organization
   */
  @Action(Entity.Upsert)
  async upsertEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.Upsert
  ) {
    const { groupId, entityId, entity, ownerImages, ownersUpdated } = action;

    const org = ctx.getState().org;
    if (!org.entities) {
      throw new Error("Organization entities do not exist!");
    }

    const existingGroupId = org.getEntityGroupId(entityId);
    const newGroupId = groupId;

    const upsertEntityCallable = httpsCallable<
      IUpsertProEntityRequest,
      IHttpsCallableResponse
    >(this.functions, "upsertProEntity");

    const entityWithTimestamps = convertDatesToTimestamps(entity) as IEntity;

    const upsertEntityResponse = await upsertEntityCallable({
      orgId: org.id,
      newGroupId,
      existingGroupId,
      entityId,
      entity: entityWithTimestamps,
      ownerImages,
      ownersUpdated,
    });

    if (!upsertEntityResponse.data.success) {
      this.snackbar.open(upsertEntityResponse.data.message, "Dismiss", {
        duration: 5000,
      });
    }

    const existingGroup = getExistingGroup(
      existingGroupId,
      newGroupId,
      org,
      entityId
    );

    const newGroup = getNewGroup(newGroupId, entityId, org, entity.legalName);

    ctx.patchState({
      org: new OrganizationStateObject({
        ...org,
        entities: {
          ...org.entities,
          [entityId]: entity,
        } as IEntityMap,
        groups: {
          ...org.groups,
          ...existingGroup,
          ...newGroup,
        },
      }),
    });

    const groupChanged = existingGroupId !== newGroupId;
    ctx.dispatch(new Entity.UpsertSuccess(groupChanged, ownersUpdated));
  }

  @Action(Entity.UpsertSuccess)
  async upsertEntitySuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.UpsertSuccess
  ) {
    try {
      const { groupChanged, ownersUpdated } = action;
      const org = ctx.getState().org;
      if (groupChanged || ownersUpdated) {
        ctx.dispatch(new Entity.GetList(Object.keys(org.groups || {})));
      }
      if (ownersUpdated) {
        ctx.dispatch(new Owner.GetPublicOwners());
      } else {
        const stateOwners = ctx.getState().org.publicOwners;
        if (stateOwners) {
          ctx.dispatch(new Owner.GetPublicOwnersSuccess(stateOwners));
        }
      }
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.TransferToSecureFile)
  async transferToSecureFile(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.TransferToSecureFile
  ) {
    try {
      const { entityId, ownerId } = action;

      const org = ctx.getState().org;
      const entity = await org.fetchEntityById(entityId);
      const entityDetails = this.store.selectSnapshot<EntityDetailsStateModel>(
        (state) => state.entityDetails
      );
      const owner = await org.fetchPublicOwner(ownerId);
      if (!entity || !owner || !entityDetails) {
        throw new Error("Entity or owner does not exist!");
      }
      const batch = writeBatch(this.firestore);

      // First, add the entity and owner to their respective collections
      const secureFileCollectionRef = collection(
        this.firestore,
        "secureFileEntities"
      );
      const secureFileDoc = doc(secureFileCollectionRef, entityId);
      batch.set(secureFileDoc, {
        ...entity,
        managingOrgId: org.id,
        owners: entity.owners,
        userIds: [],
        handedOff: true,
        selfBilled: false,
      } satisfies IEntity);

      // Now we can mark the entity as secure file
      const updatedEntity: IEntity = {
        ...entity,
        secureFile: true,
        handedOff: true,
        selfBilled: false,
      };
      const entityDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "entityList",
        entity.id
      );
      batch.update(entityDoc, {
        secureFile: true,
        handedOff: true,
        selfBilled: false,
      });
      await batch.commit();

      const updatedEntityDetails: IEntityDetails = {
        ...entityDetails,
        userIds: [...entityDetails.userIds],
        filings: [
          ...entityDetails.filings,
          {
            entitySnapshot: updatedEntity,
            snapshotDate: new Date(),
            reportType: "Handoff",
          },
        ],
        secureFileTransferComplete: false,
      };

      delete (<Partial<IEntityDetails>>updatedEntityDetails).jwtToken;

      ctx.dispatch(new EntityDetails.Set(entityId, updatedEntityDetails));

      const inviteUserCallable = httpsCallable<
        ISendSecureFileTransferEmailRequest,
        IHttpsCallableResponse
      >(this.functions, "sendInvitationEmail");

      if (!owner.email) {
        throw new Error("Owner does not have an email!");
      }
      const inviteResponse = await inviteUserCallable({
        email: owner.email,
        ownerName: `${owner.firstName} ${owner.lastName}`,
        entityId: entityId,
        groupId: entity.groupId,
        entityName: entity?.legalName,
        orgId: org.id,
        orgName: org.name,
      });

      if (org && org.entities) {
        ctx.patchState({
          org: new OrganizationStateObject({
            ...org,
            entities: {
              ...org.entities,
              [entityId]: updatedEntity,
            } as IEntityMap,
          }),
        });
      }

      if (!inviteResponse.data.success) {
        throw new Error(inviteResponse.data.message);
      }

      this.snackbar.open(
        `User successfully invited!`,
        "Dismiss", { duration: 5000 }
      );
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.InstantlyTransferToSecureFile)
  async instantlyTransferToSecureFile(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.InstantlyTransferToSecureFile
  ) {
    try {
      const { entityId, userId, email, displayName } = action;

      const org = ctx.getState().org;
      const entity = await org.fetchEntityById(entityId);
      const entityDetails = this.store.selectSnapshot<EntityDetailsStateModel>(
        (state) => state.entityDetails
      );
      if (!entity || !entityDetails) {
        throw new Error("Entity or owner does not exist!");
      }
      const batch = writeBatch(this.firestore);

      const secureFileCollectionRef = collection(
        this.firestore,
        "secureFileEntities"
      );
      const secureFileDoc = doc(secureFileCollectionRef, entityId);
      batch.set(secureFileDoc, {
        ...entity,
        managingOrgId: org.id,
        owners: entity.owners,
        userIds: [userId],
        handedOff: true,
      } satisfies IEntity);

      const entityDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "entityList",
        entity.id
      );
      batch.update(entityDoc, {
        secureFile: true,
        handedOff: true,
      });
      await batch.commit();

      const updatedEntityDetails: IEntityDetails = {
        ...entityDetails,
        userIds: [...new Set([...entityDetails.userIds, userId])],
        filings: [
          ...entityDetails.filings,
          {
            entitySnapshot: {
              ...entity,
              secureFile: true,
              handedOff: true,
            },
            snapshotDate: new Date(),
            reportType: "Handoff",
          },
        ],
        secureFileTransferComplete: true,
      };

      delete (<Partial<IEntityDetails>>updatedEntityDetails).jwtToken;

      ctx.dispatch(new EntityDetails.Set(entityId, updatedEntityDetails));

      const sendInstantlyTransferEmailCallable = httpsCallable<
        ISendInstantlyTransferEmailRequest,
        IHttpsCallableResponse
      >(this.functions, "sendInstantlyTransferEmail");

      if (!email) {
        throw new Error("Owner does not have an email!");
      }
      const response = await sendInstantlyTransferEmailCallable({
        email,
        displayName,
        entityId: entityId,
        entityName: entity?.legalName,
        orgName: org.name,
      });

      if (org && org.entities) {
        ctx.patchState({
          org: new OrganizationStateObject({
            ...org,
            entities: {
              ...org.entities,
              [entityId]: {
                ...entity,
                secureFile: true,
                handedOff: true,
              },
            } as IEntityMap,
          }),
        });
      }

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.RevokeSecureFileInvite)
  async revokeSecureFileInvite(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.RevokeSecureFileInvite
  ) {
    try {
      const { entityId } = action;

      const org = ctx.getState().org;
      const entity = await org.fetchEntityById(entityId);

      if (!entity) {
        throw new Error("Entity does not exist!");
      }

      const revokeSecureFileInviteCallable = httpsCallable<
        IRevokeSecureFileInviteRequest,
        IHttpsCallableResponse
      >(this.functions, "revokeSecureFileInvite");

      const revokeResponse = await revokeSecureFileInviteCallable({
        entityId,
        groupId: entity.groupId,
        orgId: org.id,
      });

      if (!revokeResponse.data.success) {
        throw new Error(revokeResponse.data.message);
      }

      this.snackbar.open(`SecureFILE invite successfully revoked!`, "Dismiss", {
          duration: 5000
      });

      ctx.dispatch([
        new EntityDetails.Get(entityId),
        new Entity.GetList(Object.keys(org.groups)),
      ]);
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.Delete)
  async deleteEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.Delete
  ) {
    try {
      const { entityId } = action;

      const org = ctx.getState().org;
      const entity = await org.fetchEntityById(entityId);
      const entityDetails = this.store.selectSnapshot<EntityDetailsStateModel>(
        (state) => state.entityDetails
      );

      if (!entity) {
        throw new Error("Entity does not exist!");
      }

      const groupId = entity.groupId;

      const publicOwnersFromDb = await org.getPublicOwnersByEntityId(entityId);

      const publicOwners = publicOwnersFromDb.filter(
        (owner) => !!owner
      ) as IPublicOwner[];

      // We need to delete any public owners where the
      // entity is the only entity that the owner is associated with
      const publicOwnersToDelete = publicOwners.filter((owner) => {
        const entities = owner.entities;
        const entityIds = Object.keys(entities);
        if (entityIds.length === 1 && entityIds[0] === entityId) {
          return true;
        }
        return false;
      });

      const publicOwnersWithEntityRemoved = publicOwners.map((owner) => {
        const entities = owner.entities;
        const entityIds = Object.keys(entities);
        if (entityIds.includes(entityId) && entityIds.length > 1) {
          delete entities[entityId];

          const groupIds: string[] = entityIds.reduce((acc, entityId) => {
            const entity = entities[entityId];
            if (entity && entity.groupId) {
              acc.push(entity.groupId);
            }
            return acc;
          }, [] as string[]);

          return {
            ...owner,
            entities,
            groupIds,
          };
        }
        return owner;
      });

      const batch = writeBatch(this.firestore);

      // Delete the entity from the bin
      const entityDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "entityList",
        entity.id
      );
      batch.delete(entityDoc);

      // Delete the entity details
      const entityDetailsDoc = doc(this.firestore, "entityDetails", entityId);

      if (!entity.handedOff && entityDetails.userIds.length === 0) {
        batch.delete(entityDetailsDoc);
      } else {
        // remove org access from entity details
        batch.update(entityDetailsDoc, {
          orgId: deleteField(),
        });
      }

      // Delete the public owners
      publicOwnersToDelete.forEach((owner) => {
        const ownerDoc = doc(
          this.firestore,
          "organization",
          org.id,
          "publicOwnerList",
          owner.id
        );
        batch.delete(ownerDoc);
      });

      // Update the public owners
      publicOwnersWithEntityRemoved.forEach((owner) => {
        if (publicOwnersToDelete.includes(owner)) {
          return;
        }
        const ownerDoc = doc(
          this.firestore,
          "organization",
          org.id,
          "publicOwnerList",
          owner.id
        );
        batch.update(ownerDoc, {
          ...owner,
        });
      });

      // Remove the entity from the group
      const groupDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "groups",
        groupId
      );

      // Remove the entity from the group
      const groupEntities = org.groups[groupId].entities;
      const updatedGroupEntities = groupEntities.filter(
        (entity) => entity.id !== entityId
      );
      batch.update(groupDoc, {
        entities: updatedGroupEntities,
      });

      await batch.commit();

      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          groups: {
            ...org.groups,
            [groupId]: {
              ...org.groups[groupId],
              entities: updatedGroupEntities,
            },
          },
        }),
      });

      ctx.dispatch(new Entity.DeleteSuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.DeleteSuccess)
  async deleteEntitySuccess(ctx: StateContext<SecureProStateModel>) {
    try {
      const userId = this.auth.currentUser?.uid;
      if (!userId) {
        throw new Error("User does not exist!");
      }
      const groups = ctx.getState().org.getUserGroups(userId);
      ctx.dispatch(new Entity.GetList(groups));
      ctx.dispatch(new Owner.GetPublicOwners());
      this.snackbar.open("Entity deleted successfully!", "Dismiss", {
        duration: 5000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.SelfBill)
  async selfBillEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.SelfBill
  ) {
    try {
      const { entityUpdates } = action;

      const org = ctx.getState().org;

      const batch = writeBatch(this.firestore);

      const entityUpdatePromises = entityUpdates.map(async (entityUpdate) => {
        const entity = await org.fetchEntityById(entityUpdate.id);
        if (!entity) {
          throw new Error("Entity does not exist!");
        }

        const entityDoc = doc(
          this.firestore,
          "organization",
          org.id,
          "entityList",
          entity.id
        );

        if (!entityDoc) {
          throw new Error("Entity doc does not exist!");
        }

        batch.update(entityDoc, {
          selfBilled: entityUpdate?.selfBilled,
        });

        if (org && org.entities) {
          ctx.patchState({
            org: new OrganizationStateObject({
              ...org,
              entities: {
                ...org.entities,
                [entity.id]: {
                  ...org.entities[entity.id],
                  selfBilled: entityUpdate.selfBilled,
                },
              } as IEntityMap,
            }),
          });
        }
      });
      await Promise.all(entityUpdatePromises);

      await batch.commit();

      ctx.dispatch(new Entity.SelfBillSuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.LoadAutoCollectExclusions)
  async loadEntityAutoCollectExclusions(
    ctx: StateContext<SecureProStateModel>
  ) {
    try {
      const org = ctx.getState().org;
      if (!org) {
        throw new Error("Organization does not exist!");
      }

      const entityAutoCollectExclusionsRef = collection(
        this.firestore,
        "organization",
        org.id,
        "autoCollectEntityExclusions"
      );

      const entityAutoCollectExclusionsSnapshot = await getDocs(
        entityAutoCollectExclusionsRef
      );

      const entityAutoCollectExclusions = entityAutoCollectExclusionsSnapshot.docs.reduce(
        (acc, doc) => {
          acc[doc.id] = doc.data() as { id: string };
          return acc;
        },
        {} as IEntityAutoCollectExclusionMap
      );

      ctx.dispatch(new Entity.LoadAutoCollectExclusionsSuccess(entityAutoCollectExclusions));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.LoadAutoCollectExclusionsSuccess)
  async loadEntityAutoCollectExclusionsSuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.LoadAutoCollectExclusionsSuccess
  ) {
    try {
      const { entityAutoCollectExclusionMap } = action;
      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          autoCollectEntityExclusions: entityAutoCollectExclusionMap,
        }),
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.AutoCollectExclusion)
  async autoCollectExclusionEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.AutoCollectExclusion
  ) {
    try {
      const { entityUpdate } = action;

      const org = ctx.getState().org;

      const batch = writeBatch(this.firestore);

      const entityAutoCollectExclusionRef = collection(
        this.firestore,
        "organization",
        org.id,
        "autoCollectEntityExclusions"
      );

      if (entityUpdate.autoCollectExcluded) {
        const entityExclusionDoc = doc(entityAutoCollectExclusionRef, entityUpdate.id);
        batch.set(entityExclusionDoc, { id: entityUpdate.id });
      } else {
        const entityExclusionDoc = doc(entityAutoCollectExclusionRef, entityUpdate.id);
        batch.delete(entityExclusionDoc);
      }

      await batch.commit();

      ctx.dispatch(new Entity.AutoCollectExclusionSuccess(entityUpdate));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.AutoCollectExclusionSuccess)
  async autoCollectExclusionEntitySuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.AutoCollectExclusionSuccess
  ) {
    try {
      const { entityUpdate } = action;
      const entityAutoCollectExclusionMap = ctx.getState().org.autoCollectEntityExclusions || {};
      const updatedEntityAutoCollectExclusionMap = entityUpdate.autoCollectExcluded
      ? {
        ...entityAutoCollectExclusionMap,
        [entityUpdate.id]: { id: entityUpdate.id },
      }
      : Object.keys(entityAutoCollectExclusionMap).reduce((acc, id) => {
        if (id !== entityUpdate.id) {
          acc[id] = entityAutoCollectExclusionMap[id];
        }
        return acc;
      }, {} as IEntityAutoCollectExclusionMap);

      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          autoCollectEntityExclusions: updatedEntityAutoCollectExclusionMap,
        }),
      });

      this.snackbar.open("Entity auto collect exclusion updated successfully!", "Dismiss", {
        duration: 5000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.SelfBillSuccess)
  async selfBillEntitySuccess() {
    try {
      this.snackbar.open("Entity self bill updated successfully!", "Dismiss", {
        duration: 5000,
      });
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.SubmitToFinCEN)
  async submitToFinCEN(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.SubmitToFinCEN
  ) {
    try {
      const { entityUpdate } = action;

      const org = ctx.getState().org;

      const entity = await org.fetchEntityById(entityUpdate.entityId);
      if (!entity) {
        throw new Error("Entity does not exist!");
      }

      const entityDetails = this.store.selectSnapshot<EntityDetailsStateModel>(
        (state) => state.entityDetails
      );
      if (!entityDetails) {
        throw new Error("entityDetails does not exist!");
      }

      const enqueueResponse = await this.boirFilingsService.enqueueBOIR(
        entity,
        entityUpdate.consentData,
        org.id
      );

      if (!enqueueResponse) {
        return;
      }

      if (entityUpdate.isSubmittedByUser) {
        ctx.dispatch(
          new User.UpdateConsentData(
            entityUpdate.consentData.firstName,
            entityUpdate.consentData.lastName,
            entityUpdate.consentData.email
          )
        );
      }

      ctx.dispatch(new Entity.SubmitToFinCENSuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.SubmitToFinCENSuccess)
  async submitToFinCENSuccess() {
    try {
      this.snackbar.open(
        'Filing process has started and will complete on its own, please  go to "View Filings" to view records after filing',
        "Dismiss",
        {
          duration: 10000,
        }
      );
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Entity.UpdateStripeSubscription)
  async updateStripeSubscription(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.UpdateStripeSubscription
  ) {
    const org = ctx.getState().org;

    const batch = writeBatch(this.firestore);

    const entity = await org.fetchEntityById(action.entityId);

    if (!entity) {
      throw new Error("Entity does not exist!");
    }

    const entityDoc = doc(
      this.firestore,
      "organization",
      org.id,
      "entityList",
      entity.id
    );

    if (!entityDoc) {
      throw new Error("Entity doc does not exist!");
    }

    batch.update(entityDoc, {
      stripeSubscriptionId: action.subscriptionId,
    });

    if (org && org.entities) {
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          entities: {
            ...org.entities,
            [entity.id]: {
              ...org.entities[entity.id],
              stripeSubscriptionId: action.subscriptionId,
            },
          } as IEntityMap,
        }),
      });
    }

    await batch.commit();
  }

  @Action(Entity.SetEntity)
  async setEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Entity.SetEntity
  ) {
    const { entityId, entity } = action;
    const org = ctx.getState().org;
    if (!entity) {
      console.error("Entity does not exist!");
      return;
    }

    ctx.patchState({
      org: new OrganizationStateObject({
        ...org,
        entities: {
          ...org.entities,
          [entityId]: entity,
        },
      }),
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////  Owner Actions  //////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * @description Get the public owners from the public owners collection
   */
  @Action(Owner.GetPublicOwners)
  async getPublicOwners(
    ctx: StateContext<SecureProStateModel>,
    action: Owner.GetPublicOwners
  ) {
    try {
      const { owner } = action;
      const org = ctx.getState().org;

      const getFilteredPublicOwnersCallable = httpsCallable<
        { owner?: IOwner; orgId: string },
        {
          success: boolean;
          message: string;
          data: IPublicOwnerMap;
        }
      >(this.functions, "getFilteredPublicOwners");

      const filterPublicOwnersResponse = await getFilteredPublicOwnersCallable({
        owner,
        orgId: org.id,
      });

      if (!filterPublicOwnersResponse.data.success) {
        throw new Error(filterPublicOwnersResponse.data.message);
      }

      const publicOwners = filterPublicOwnersResponse.data.data;

      ctx.dispatch(new Owner.GetPublicOwnersSuccess(publicOwners));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.GetPublicOwnersSuccess)
  async getPublicOwnersSuccess(
    ctx: StateContext<SecureProStateModel>,
    action: Owner.GetPublicOwnersSuccess
  ) {
    try {
      const { owners } = action;
      const org = ctx.getState().org;
      ctx.patchState({
        org: new OrganizationStateObject({
          ...org,
          publicOwners: owners,
        }),
      });
      ctx.dispatch(
        new Collection.Set("publicOwnerList", Object.values(owners))
      );
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.Delete)
  async deleteOwner(
    ctx: StateContext<SecureProStateModel>,
    action: Owner.Delete
  ) {
    const { ownerId } = action;

    try {
      // Since we have to remove the owner from all entities,
      // we need to get all of the entities that the owner is in.
      // Once we have all of the entities, we can do a batch write to
      // remove the owner from all of the entities.
      const org = ctx.getState().org;
      // Let's get the entities from the public owners
      const publicOwners = org.publicOwnersList;

      if (!publicOwners) {
        throw new Error("No public owners!");
      }

      const publicOwnerBeingDeleted = publicOwners.find(
        (owner) => owner.id === ownerId
      );

      if (!publicOwnerBeingDeleted) {
        throw new Error("Owner not found!");
      }

      const entities = publicOwnerBeingDeleted.entities;

      // Now we can batch update the entities that had the owner
      const batch = writeBatch(this.firestore);
      for (const entityId of Object.keys(entities)) {
        const entityDoc = doc(
          this.firestore,
          "organization",
          org.id,
          "entityList",
          entityId
        );

        batch.update(entityDoc, {
          [`.owners.${ownerId}`]: deleteField(),
        });
      }

      const ownerDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "publicOwnerList",
        ownerId
      );

      batch.delete(ownerDoc);

      await batch.commit();

      ctx.dispatch(new Owner.DeleteSuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.DeleteSuccess)
  async deleteOwnerSuccess(ctx: StateContext<SecureProStateModel>) {
    try {
      // Update the state with the updated public owners
      // and the updated entity details
      ctx.dispatch(new Owner.GetPublicOwners());
      ctx.dispatch(new Entity.GetList(Object.keys(ctx.getState().org.groups)));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.RemoveFromEntity)
  async removeFromEntity(
    ctx: StateContext<SecureProStateModel>,
    action: Owner.RemoveFromEntity
  ) {
    const { ownerId, entityId } = action;

    try {
      const org = ctx.getState().org;
      const entity = await org.fetchEntityById(entityId);

      if (!entity) {
        throw new Error("Entity not found!");
      }

      const owner = Object.values(entity.owners).find(
        (owner) => owner.id === ownerId
      );

      if (owner === undefined) {
        throw new Error("Owner not found!");
      }

      const publicOwnerBeingDeleted = await org.fetchPublicOwner(ownerId);

      if (!publicOwnerBeingDeleted) {
        throw new Error("Public owner not found!");
      }

      const entities = publicOwnerBeingDeleted.entities;

      const batch = writeBatch(this.firestore);

      const entityDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "entityList",
        entityId
      );

      batch.update(entityDoc, {
        [`owners.${ownerId}`]: deleteField(),
      });

      const fileEntityDoc = doc(this.firestore, "secureFileEntities", entityId);

      if (entity.handedOff) {
        batch.update(fileEntityDoc, {
          [`owners.${ownerId}`]: deleteField(),
        });
      }

      const entitySnapshot = await getDoc(entityDoc);

      if (entitySnapshot.exists()) {
        const entity = <IEntity>entitySnapshot.data();

        delete entity.owners[ownerId];

        let entityFormationDate;

        if ((entity.formationDate as unknown) instanceof Timestamp) {
          entityFormationDate = (
            entity.formationDate as unknown as Timestamp
          ).toDate();
        } else {
          entityFormationDate = entity.formationDate;
        }

        const totalProgress = await this.entityService.getTotalEntityProgress({
          ...entity,
          formationDate: entityFormationDate,
        });

        if (totalProgress) {
          batch.update(entityDoc, {
            progress: totalProgress.totalPercentFilled,
          });

          if (entity.handedOff) {
            batch.update(fileEntityDoc, {
              progress: totalProgress.totalPercentFilled,
            });
          }
        }
      }

      const publicOwnerDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "publicOwnerList",
        ownerId
      );

      // Remove the whole public owner object if there are no more entities
      const deletePublicOwner = Object.keys(entities).length === 1;
      if (deletePublicOwner) {
        batch.delete(publicOwnerDoc);
      } else {
        batch.update(publicOwnerDoc, {
          [`entities.${entityId}`]: deleteField(),
        });
      }

      await batch.commit();

      // remove owner from state or from database
      ctx.dispatch(new EntityDetails.RemoveOwner(entityId, ownerId));

      // Now we can create the audit log to record the owner removal
      const auditLog: IAuditLog = {
        id: uuidV4(),
        type: "owner",
        typeValue: ownerId,
        date: new Date(),
        user: this.auth.currentUser?.displayName || "",
        actions: [
          {
            action: "Removed",
            details: `Owner ${owner.firstName} ${owner.lastName} was removed from the entity.`,
          },
        ],
      };
      ctx.dispatch(new EntityDetails.UpdateAuditLog(entityId, auditLog));

      // Update the state with the updated public owners
      // and the updated entity details
      ctx.dispatch(new Entity.GetList(Object.keys(org.groups)));
      ctx.dispatch(new Owner.GetPublicOwners());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.Update)
  async updateOwner(
    ctx: StateContext<SecureProStateModel>,
    action: Owner.Update
  ) {
    const { owner, ownerImage } = action;

    try {
      const org = ctx.getState().org;

      if (!owner.id) {
        throw new Error("Owner id not found!");
      }

      const publicOwner = await org.fetchPublicOwner(owner.id);

      if (!publicOwner) {
        throw new Error("Public owner not found!");
      }

      // We're going to update this entity to trigger the
      // cloud function to update the owner in all of the entities

      const publicOwnerDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "publicOwnerList",
        owner.id
      );

      await updateDoc(publicOwnerDoc, {
        ...publicOwner,
        firstName: owner.firstName,
        lastName: owner.lastName,
        email: owner.email,
        idUploaded: !!owner.docUrl,
        lastIdCollectionEmailSent: owner.lastIdCollectionEmailSent,
        countOfIdCollectionEmailsSent: owner.countOfIdCollectionEmailsSent || 0,
        lastModifiedDate: new Date(),
      });

      const entityIdToUpdate = Object.keys(publicOwner.entities)[0];
      const entityToUpdate = await org.fetchEntityById(entityIdToUpdate);
      if (!entityToUpdate) {
        throw new Error("Entity not found!");
      }

      const entityDoc = doc(
        this.firestore,
        "organization",
        org.id,
        "entityList",
        entityToUpdate.id
      );
      // Update the entity with the updated owner
      await updateDoc(entityDoc, {
        [`owners.${owner.id}`]: owner,
      });

      // Now send a request to the backend to update the owner in all of the entities
      const mirrorOwnerCallable = httpsCallable<
        IMirrorOwnerRequest,
        IHttpsCallableResponse
      >(this.functions, "mirrorOwner");

      const mirrorOwnerResponse = await mirrorOwnerCallable({
        orgId: org.id,
        ownerId: owner.id,
        entityId: entityIdToUpdate,
      });

      if (!mirrorOwnerResponse.data.success) {
        throw new Error(mirrorOwnerResponse.data.message);
      }

      if (ownerImage) {
        try {
          await this.commonApis.uploadGovIdImage(ownerImage);
        } catch (error) {
          this.snackbar.open(
            "Failed to save image. Please try again later.",
            "Dismiss",
            {
              duration: 7000,
            }
          );
          throw error;
        }
      }

      ctx.dispatch(new Owner.UpdateSuccess());
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  @Action(Owner.UpdateSuccess)
  async updateOwnerSuccess(ctx: StateContext<SecureProStateModel>) {
    try {
      // Update the state with the updated public owners
      // and the updated entity details
      ctx.dispatch(new Owner.GetPublicOwners());
      ctx.dispatch(new Entity.GetList(Object.keys(ctx.getState().org.groups)));
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }
}
