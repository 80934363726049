import { FormArray, FormControl, FormGroup } from "@angular/forms";

import {
  GovernmentIdType,
  ICustomCoverLetterContent,
  IGroupMap,
  ReportType,
} from ".";

export interface IEntityMap {
  [entityId: string]: IEntity;
}

export interface IEntityAutoCollectExclusionMap {
  [entityId: string]: {
    id: string;
  };
}

export type EntityInterfaceKeyIndices =
  | string
  | boolean
  | string[]
  | IOwnerReference
  | IEntitySubmitConsent
  | (string | null)[];

export interface IEntity {
  address: IAddress;
  formationDate: Date | null;
  isFormedAfter2024?: boolean | null;
  legalName: string;
  tradeNames: (string | null)[] | null;
  dueDate: Date | null;
  displayDueDate: string | null;
  foreignIdJurisdiction: string | null;
  jurisdictionOfFormation: string | null;
  stateOfFormation: string | null;
  tribeOfFormation: string | null;
  otherTribeOfFormation: string | null;
  taxId: string | null;
  taxIdType: string | null;
  status: EntityStatuses;
  fincenStatus: SubmissionStatuses | null;
  lastAcceptedFiling: Date | null;
  internalNotes: string | null;
  assignedTo: IAssignedTo | null;
  progress: number;
  secureFile: boolean;
  groupId: string;
  foreignPooledInvestmentVehicle: boolean;
  owners: IOwnerReference;
  stripeSubscriptionId: string | null;
  selfBilled: boolean;
  handedOff: boolean; // Indicates if the entity has been handed off to secure file
  consentData: IEntitySubmitConsent | null;
  reportType: ReportType;
  fincenId: string | null;
  optOutOfFincenId: boolean;
  additionalExemptions: string | null;
  isLargeOperatingCompany: boolean | null;
  isInactive: boolean | null;
  userIds: string[];
  managingOrgId: string | null;
  id: string;
  groupName: string;
  lastModifiedDate: Date | null;
  inSubscriptionBucket?: boolean;
  progressMissingFields?: IMissingFields[];
  bulkUploaded?: boolean;
  bulkUploadedDate?: Date;
}

export interface IOwnerReference {
  [ownerId: string]: IOwner;
}

export interface IMissingFields {
  [key: string]: string[];
}
export interface IObjectProgress {
  objectType: "entity" | "beneficial_owner" | "company_applicant";
  countOfRequiredFields: number;
  countOfFieldsFilled: number;
  percentFilled: number;
  missingFields: IMissingFields;
}
export interface IEntityTotalProgress {
  totalCountOfRequiredFields: number;
  totalCountOfFieldsFilled: number;
  totalPercentFilled: number;
  totalMissingFields: IMissingFields[];
  entityProgress: IObjectProgress;
  ownersProgress: IObjectProgress[];
}

export interface IOwnerRequiredFields {
  isEntity: string[];
  isExemptEntity: string[];
  withFincenId: string[];
  withGovId: string[];
}
export interface IEntityRequiredFields {
  entityFields: string[];
}

export interface IBaseEntityForm {
  legalName: FormControl<string | null>;
  tradeNames: FormArray<FormControl<string | null>>;
  formationDate: FormControl<Date | null>;
  isFormedAfter2024: FormControl<boolean | null>;
  foreignIdJurisdiction: FormControl<string | null>;
  jurisdictionOfFormation: FormControl<string | null>;
  stateOfFormation: FormControl<string | null>;
  tribeOfFormation: FormControl<string | null>;
  otherTribeOfFormation: FormControl<string | null>;
  taxId: FormControl<string | null>;
  taxIdType: FormControl<string | null>;
  address: FormGroup<IAddressForm>;
  // foreignPooledInvestmentVehicle: FormControl<boolean | null>;
}

export interface IEntityForm extends IBaseEntityForm {
  id: FormControl<string | null>;
  groupId: FormControl<string | null>;
  dueDate: FormControl<Date | null>;
  absoluteDueDate: FormControl<Date | null>;
  displayDueDate: FormControl<string | null>;
  status: FormControl<EntityStatuses | null>;
  internalNotes: FormControl<string | null>;
  assignedTo: FormGroup<IAssignedToForm>;
  reportType: FormControl<ReportType | null>;
  fincenId: FormControl<string | null>;
  optOutOfFincenId: FormControl<boolean | null>;
  additionalExemptions: FormControl<string | null>;
  isLargeOperatingCompany: FormControl<boolean | null>;
  isInactive: FormControl<boolean | null>;
  selfBilled: FormControl<boolean | null>;
}

export interface IAddress {
  city: string | null;
  state: string | null;
  streetLine1: string | null;
  streetLine2: string | null;
  zip: string | null;
  country: string | null;
}

export interface IAddressForm {
  city: FormControl<string | null>;
  state: FormControl<string | null>;
  streetLine1: FormControl<string | null>;
  streetLine2: FormControl<string | null>;
  zip: FormControl<string | null>;
  country: FormControl<string | null>;
}

export interface IAssignedTo {
  userId: string | null;
  userName: string | null;
}

export interface IAssignedToForm {
  userId: FormControl<string | null>;
  userName: FormControl<string | null>;
}

export type SubmissionStatuses =
  | "submission_accepted"
  | "submission_rejected"
  | "submission_processing"
  | "submission_validation_failed"
  | "submission_validation_passed"
  | "submission_pending";

export interface IFiling {
  reportType: ReportType;
  entitySnapshot: IEntity;
  snapshotDate: Date;
  processId?: string;
  submissionStatus?: SubmissionStatuses;
}

export interface IEntityDetails {
  orgId: string;
  userIds: string[];
  auditLog: IAuditLog[];
  filings: IFiling[];
  jwtToken: string;
  secureFileTransferComplete: boolean;
  ownerIds: string[];
}

export interface IEntityQualifiers {
  email: string;
  firstName: string;
  lastName: string;
  requestFincenId?: boolean;
}

export interface IAuditLog {
  id: string;
  date: Date;
  user: string;
  actions: IAuditLogAction[];
  type: "entity" | "owner";
  typeValue: string; // entityId or ownerId
}

export interface IAuditLogAction {
  action: string;
  details: string;
}
export type AuditLogValue =
  | Partial<IEntity>
  | Partial<IOwner>
  | null
  | string
  | IAddress
  | Date
  | undefined;
export type ValuesForAuditLog = { [key: string]: AuditLogValue };

export interface IAuditLogBuildConfig {
  newValues: Partial<IEntity> | Partial<IOwner> | null;
  oldValues: Partial<IEntity> | Partial<IOwner> | null;
  itemsToNotStoreFullCopy: string[];
  type: "entity" | "owner";
  groups?: IGroupMap;
}

export interface IOwnerBase {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  lastIdCollectionEmailSent: Date | null;
  countOfIdCollectionEmailsSent: number;
  isCompanyApplicant: boolean;
  autoCollectId: boolean;
  lastModifiedDate: Date | null;
  fincenId: string | null;
  // Information about the owner if it's an entity
  isEntity: boolean | null;
  entityLegalName: string | null;
}

export interface IOwner extends IOwnerBase {
  middleName: string | null;
  suffix: string | null;
  dob: Date | null;
  displayDob: string | null;
  jurisdictionIssuer: string | null;
  issuerState: string | null;
  issuerTribe: string | null;
  issuerOtherTribe: string | null;
  docType: GovernmentIdType | null;
  docIdNum: string | null;
  docUrl: string | null;
  hasFincenId: boolean | null;
  addressType: string | null;
  address: IAddress;
  internalNotes: string | null;
  minor: boolean | null;
  originalAttachmentFileName: string | null;
  // Information about the owner if it's an entity
  isExemptEntity: boolean | null;
  isSignatoryEntity: boolean | null;
}

export interface IOwnerForm {
  id: FormControl<string | null>;
  firstName: FormControl<string | null>;
  middleName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  suffix: FormControl<string | null>;
  email: FormControl<string | null>;
  dob: FormControl<Date | null>;
  absoluteDob: FormControl<Date | null>;
  displayDob: FormControl<string | null>;
  jurisdictionIssuer: FormControl<string | null>;
  issuerState: FormControl<string | null>;
  issuerTribe: FormControl<string | null>;
  issuerOtherTribe: FormControl<string | null>;
  docType: FormControl<GovernmentIdType | null>;
  docIdNum: FormControl<string | null>;
  docUrl: FormControl<string | null>;
  fincenId: FormControl<string | null>;
  hasFincenId: FormControl<boolean | null>;
  addressType: FormControl<string | null>;
  address: FormGroup<IAddressForm>;
  lastIdCollectionEmailSent: FormControl<Date | null>;
  countOfIdCollectionEmailsSent: FormControl<number>;
  isCompanyApplicant: FormControl<boolean>;
  autoCollectId: FormControl<boolean>;
  internalNotes: FormControl<string | null>;
  minor: FormControl<boolean | null>;
  // Information about the owner if it's an entity
  isEntity: FormControl<boolean | null>;
  entityLegalName: FormControl<string | null>;
  isExemptEntity: FormControl<boolean | null>;
  isSignatoryEntity?: FormControl<boolean | null>;
}

export interface IAutoCollectOwner {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  email: string | null;
  phone: string | null;
  docIdNum: string | null;
  docType: GovernmentIdType | null;
  dob: Date | null;
  displayDob: string | null;
  jurisdictionIssuer: string | null;
  fincenId: string | null;
  issuerState: string | null;
  lastModifiedDate: Date | null;
  address: IAddress;
}

export interface IAutoCollectOwnerForm {
  name: FormGroup<IAutoCollectOwnerNameForm>;
  email: FormControl<string | null>;
  dob: FormControl<Date | null>;
  absoluteDob: FormControl<Date | null>;
  displayDob: FormControl<string | null>;
  identification: FormGroup<IAutoCollectOwnerIdForm>;
  hasFincenId: FormControl<boolean | null>;
  fincenId: FormControl<string | null>;
  address: FormGroup<IAddressForm>;
}

export interface IAutoCollectOwnerNameForm {
  firstName: FormControl<string | null>;
  middleName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  suffix: FormControl<string | null>;
}

export interface IAutoCollectOwnerIdForm {
  docIdNum: FormControl<string | null>;
  docType: FormControl<GovernmentIdType | null>;
  // dob: FormControl<Date | null>;
  jurisdictionIssuer: FormControl<string | null>;
  issuerState: FormControl<string | null>;
  issuerTribe: FormControl<string | null>;
  issuerOtherTribe: FormControl<string | null>;
}

/**
 * The auto collect form should only show the fields that are
 * required from the user. This is what is returned from the
 * backend to tell the front end which fields to show.
 */
export interface IAutoCollectActiveFields {
  name: boolean;
  email: boolean;
  dob: boolean;
  identification: boolean;
  address: boolean;
  fincenId: boolean;
}

export interface IAutoCollectPrefilledFields {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  address: IAddress;
}

export interface IOrgContacts {
  orgPhone: string | null;
  orgEmail: string | null;
}

export interface IOrgContactsForm {
  orgPhone: FormControl<string | null>;
  orgEmail: FormControl<string | null>;
}

export type EntityStatuses =
  | "Set Up"
  | "Ready to Review"
  | "Failed at Review"
  | "Ready to File"
  | "e-Filing Submitted"
  | "e-Filing Rejected"
  | "e-Filing Accepted"
  | "e-Filing Pending"
  | "Exempt";

export const entityStatuses: { status: EntityStatuses; automated: boolean }[] =
  [
    { status: "Set Up", automated: false },
    { status: "Ready to Review", automated: false },
    { status: "Failed at Review", automated: false },
    { status: "Ready to File", automated: false },
    { status: "Exempt", automated: false },
    { status: "e-Filing Pending", automated: true },
    { status: "e-Filing Submitted", automated: true },
    { status: "e-Filing Rejected", automated: true },
    { status: "e-Filing Accepted", automated: true },
  ];

/**
 * This is a public owner, which is a subset of the owner interface.
 * It is used to display owner information regardless of whether the user has
 * permission to view the owner's full information.
 */

export interface IPublicOwnerMap {
  [ownerId: string]: IPublicOwner;
}

export interface IPublicOwner extends IOwnerBase {
  id: string;
  idUploaded: boolean;
  entities: IEntityReferences;
  idSaved: boolean | null;
  displayName: string | null;
  groupIds?: string[];
}

export interface IEntityReferences {
  [entityId: string]: IEntityReference;
}

export interface IEntityReference {
  legalName: string;
  groupId: string | undefined;
  dueDate: Date | null;
}

export interface IEntitySelfBillUpdate {
  id: string;
  selfBilled: boolean;
}

export interface IEntityAutoCollectUpdate {
  id: string;
  autoCollectExcluded: boolean;
}
export interface IGroupAutoCollectUpdate {
  id: string;
  autoCollectExcluded: boolean;
}

export interface IUserAuthorizingEntity {
  consentEmail: string | null;
  consentFirstName: string | null;
  consentLastName: string | null;
}

export interface ISubmitFincenFormSelectOption {
  value: IOwner | IUserAuthorizingEntity;
  displayName: string;
}

export interface IEntityFincenSubmitForm {
  consentEmail: FormControl<string | null>;
  consentLastName: FormControl<string | null>;
  consentFirstName: FormControl<string | null>;
  authorizingEntity: FormControl<ISubmitFincenFormSelectOption["value"] | null>;
  paymentChecked: FormControl<boolean | null>;
  consentChecked: FormControl<boolean | null>;
}

export interface IEntitySubmitConsent {
  firstName: string;
  lastName: string;
  email: string;
  timestamp: string;
}

export interface IEntitySubmitToFinCENUpdate {
  entityId: string;
  consentData: IEntitySubmitConsent;
  status: EntityStatuses;
  fincenStatus: SubmissionStatuses;
  isSubmittedByUser?: boolean;
}

export interface ICoverLetterData {
  companyName: string;
  orgLogo?: string;
  userName: string;
  currentDate: string;
  customCoverLetterContent?: ICustomCoverLetterContent;
}

export interface IUploadAttachment {
  processId: string;
  fileName: string;
  fileData: string;
  accessToken: string;
  contentType: string;
}

export interface IUploadAttachmentResponse {
  response: string;
  fileName: string;
  ownerId: string;
}

export interface IBoirFilingResponse {
  processId: string;
  xmlUploadResponse: string;
  attachmentUploadResponses: IUploadAttachmentResponse[];
}

export interface IEntityUpdates {
  status: EntityStatuses;
  fincenStatus: SubmissionStatuses | null;
  fincenId?: string;
}
