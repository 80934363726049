import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { IUserQuickStartState, Permission } from "../interfaces";
import { AuthService } from "../services/auth.service";

/**
 * Function that generates a default Quick Start state
 */
export const defaultQuickStartState = (): IUserQuickStartState => ({
  users: {
    finished: false,
    position: 1,
  },
  multiFactor: {
    finished: false,
    position: 0,
  },
  groups: {
    finished: false,
    position: 2,
  },
  entities: {
    finished: false,
    position: 3,
  },
});

interface IQuickStartStatus {
  tasksRemaining: number;
  percentComplete: number;
}

interface QuickStartStepInstructions {
  title: string;
  description: string;
  knowledgeBaseLink: string;
  actionUrl: string;
  requiredPermission?: Permission;
}

interface QuickStartStepInstructionsIndexable {
  [key: string]: QuickStartStepInstructions;
}

export const quickStartSteps: QuickStartStepInstructionsIndexable = {
  groups: {
    title: "Create a group",
    description:
      "Groups control who has access to which Entities, enhancing privacy and security.",
    knowledgeBaseLink: "https://vortechsolutions.atlassian.net/wiki/x/BAD0Cw",
    actionUrl: "/secure-pro/groups",
    requiredPermission: "manageGroups",
  },
  users: {
    title: "Invite a user",
    description:
      "Invite users to your organization. Users can be assigned to groups and given access to entities.",
    knowledgeBaseLink: "https://vortechsolutions.atlassian.net/wiki/x/AYAfAg",
    actionUrl: "/secure-pro/users",
    requiredPermission: "manageUsers",
  },
  multiFactor: {
    title: "Enable multi-factor authentication",
    description:
      "Multi-factor authentication adds an additional layer of security to your account.",
    knowledgeBaseLink: "https://vortechsolutions.atlassian.net/wiki/x/BQD6Cw",
    actionUrl: "/auth/two-factor-setup",
  },
  entities: {
    title: "Create an entity",
    description:
      "An 'Entity' refers to a customer's company that needs to be filed.",
    knowledgeBaseLink: "https://vortechsolutions.atlassian.net/wiki/x/DICLC",
    actionUrl: "/entities/new",
  },
};

export const filteredQuickStartSteps = (
  authService: AuthService
): QuickStartStepInstructionsIndexable => {
  if (authService.isPasswordUser) {
    return {
      groups: quickStartSteps["groups"],
      users: quickStartSteps["users"],
      entities: quickStartSteps["entities"],
    };
  } else {
    return quickStartSteps;
  }
};

export const calculatePercentComplete = (
  userState: UserStateModel
): IQuickStartStatus | undefined => {
  const userPermissions = userState.permissions;

  const qsState = userState.quickStartState;
  if (!qsState) {
    return;
  }
  const userAvailableSteps = Object.keys(qsState)
    .filter((qs) => {
      const step = quickStartSteps[qs];
      if (
        !step.requiredPermission ||
        userPermissions.includes(step.requiredPermission)
      ) {
        return true;
      }
      return false;
    })
    .filter((qs) => {
      const step = quickStartSteps[qs];
      if (
        // isPasswordUser() &&
        step.title === "Enable multi-factor authentication"
      ) {
        return false;
      } else {
        return true;
      }
    })
    .reduce((acc, curr) => {
      acc[curr] = qsState[curr];
      return acc;
    }, {} as IUserQuickStartState);

  const total = Object.keys(userAvailableSteps).length;
  const finished = Object.values(userAvailableSteps).filter(
    (v) => v.finished
  ).length;
  const percentComplete = Math.round((finished / total) * 100);
  const tasksRemaining = total - finished;
  return { percentComplete, tasksRemaining };
};
