import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { emailRegex, usTerritories } from "../utils/constants";

@Injectable({
  providedIn: "root",
})
export class FormValidationService {
  public static taxIdValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control.parent;
      if (!formGroup) return null;

      const taxIdType = formGroup.get("taxIdType")?.value;
      const taxId = control.value;

      const numbers = taxId?.match(/\d/g);

      if (taxId && numbers?.length !== 9 && taxIdType !== "Foreign") {
        control.setErrors({ invalidLength: true });
        return { invalidLength: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static fincenIdValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formGroup = control.parent;
      if (!formGroup) return null;

      const fincenId = control.value;
      const numbers = fincenId?.match(/\d/g);

      if (fincenId && numbers.length !== 12) {
        control.setErrors({ invalid: true });
        return { invalid: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static emailValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const email = control.value;

      if (email && !emailRegex.test(email)) {
        control.setErrors({ invalid: true });
        return { invalid: true };
      } else {
        control.setErrors(null);
        return null;
      }
    };
  };

  public static usPhoneValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const numbers = control.value?.match(/\d/g);
      if (control.value && !numbers) return { invalid: true };
      if (control.value && numbers.length !== 10) {
        return { invalid: true };
      }
      return null;
    };
  };

  /**
   * Validator to ensure the input is not composed only of whitespace.
   */
  public static notOnlyWhitespaceValidator = (): ValidatorFn =>
    Validators.pattern(/^(?!\s+$).*$/);

  taxIdPlaceholder(taxIdType?: string | null) {
    switch (taxIdType) {
      case "EIN":
        return "12-3456789";
      case "SSN/ITIN":
        return "123-45-6789";
      default:
        return "123456789";
    }
  }

  taxIdInputMask(taxIdType?: string | null) {
    switch (taxIdType) {
      case "EIN":
        return { mask: "00-0000000" };
      case "SSN/ITIN":
        return { mask: "000-00-0000" };
      default:
        return {
          mask: "[#########################]",
          definitions: {
            "#": /[0-9a-zA-Z]/,
          },
        };
    }
  }

  public zipCodeMask = (country?: string | null) => {
    if (
      country &&
      country !== "United States" &&
      !usTerritories.includes(country)
    ) {
      return {
        mask: "[#########]",
        definitions: {
          "#": /[0-9a-zA-Z]/,
        },
      };
    } else {
      return {
        mask: "[#########]",
        definitions: {
          "#": /\d/,
        },
      };
    }
  };

  public zipCodeLength = (country?: string | null): [number, number] => {
    if (
      country &&
      country !== "United States" &&
      !usTerritories.includes(country)
    ) {
      return [1, 9];
    } else {
      return [5, 9];
    }
  };
}
