import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Timestamp } from "firebase/firestore";
import { ReportType } from "../interfaces";
import { prohibitedWords } from "./constants";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const convertToFirestoreTimestamp = (obj: any): any => {
  if (obj && typeof obj === "object") {
    if (
      (obj._seconds !== undefined || obj.seconds !== undefined) &&
      (obj._nanoseconds !== undefined || obj.nanoseconds !== undefined)
    ) {
      const seconds = parseInt(obj._seconds || obj.seconds, 10) || 0;
      const nanoseconds =
        parseInt(obj._nanoseconds || obj.nanoseconds, 10) || 0;
      return new Timestamp(seconds, nanoseconds);
    }
    Object.keys(obj).forEach((key) => {
      obj[key] = convertToFirestoreTimestamp(obj[key]);
    });
  }
  return obj;
};

/**
 * @description Given any object, convert all of the Timestamps to Dates
 */
export const convertTimestampsToDates = (obj: any): any => {
  if (obj && typeof obj === "object") {
    if (obj instanceof Timestamp) {
      return obj.toDate();
    }
    if (obj?._seconds && obj?._nanoseconds) {
      return new Date(obj._seconds * 1000 + obj._nanoseconds / 1000000);
    }
    Object.keys(obj).forEach((key) => {
      obj[key] = convertTimestampsToDates(obj[key]);
    });
  }
  return obj;
};

/**
 * @description Given any object, convert all of the Dates to Timestamps
 */
export const convertDatesToTimestamps = <T>(obj: any): T => {
  if (obj instanceof Date) {
    return Timestamp.fromDate(obj) as any;
  }
  if (Array.isArray(obj)) {
    return obj.map(convertDatesToTimestamps) as any;
  }
  if (obj && typeof obj === "object") {
    const newObject = {} as any;
    Object.keys(obj).forEach((key) => {
      newObject[key] = convertDatesToTimestamps(obj[key]);
    });
    return newObject as T;
  }
  return obj;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const containsProhibitedWords = (obj: unknown): string | null => {
  const jsonString = JSON.stringify(obj).toLowerCase();

  for (const prohibitedWord of prohibitedWords) {
    const regex = new RegExp(`"\\s*${prohibitedWord}\\s*"`, "gi");
    if (regex.test(jsonString)) {
      return prohibitedWord;
    }
  }

  return null;
};

/**
 * @description Given an object, flatten it into a single level object
 */
export const flattenObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  separate = true,
  parentKey = "",
  separator = "."
): object => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flatObject: any = {};

  Object.keys(obj).forEach((key) => {
    const newKey = separate
      ? parentKey
        ? `${parentKey}${separator}${key}`
        : key
      : key;

    // Check if the current property is a Date object
    if (obj[key] instanceof Date) {
      flatObject[newKey] = obj[key];
    } else if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      const nestedObject = flattenObject(obj[key], separate, newKey, separator);
      Object.assign(flatObject, nestedObject);
    } else {
      flatObject[newKey] = obj[key];
    }
  });

  return flatObject;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeDuplicates = (arr: any[]) => {
  return [...new Set(arr)];
};

export const reportTypeValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    // a mini function to check if the report type is valid
    const isReportType = (type: string): type is ReportType => {
      return [
        "BOIR - Initial",
        "BOIR - Correction",
        "BOIR - Update",
        "Exemption Certificate",
      ].includes(type);
    };

    const value = control.value;
    if (!isReportType(value)) {
      control.setErrors({ invalidReportType: true });
      return { invalidReportType: true };
    }

    control.setErrors(null);
    return null;
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const deepEqual = (obj1: any, obj2: any): boolean => {
  // If both values are the same reference or primitives are equal
  if (obj1 === obj2) return true;

  // If one of the values is null or not an object
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  // If both values are dates, compare their timestamps
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  // If both values are arrays, compare their length and elements
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    return obj1.every((item, index) => deepEqual(item, obj2[index]));
  }

  // Compare keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  // Recursively compare values for each key
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
