import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CamelToTitlePipe } from "./camel-to-title.pipe";
import { SortPipe } from "./sort.pipe";
import { IsCheckboxColumnPipe } from "./is-checkbox-column.pipe";
import { IsCheckboxFilterPipe } from "./is-checkbox-filter.pipe";

@NgModule({
  declarations: [
    CamelToTitlePipe,
    SortPipe,
    IsCheckboxColumnPipe,
    IsCheckboxFilterPipe
  ],
  exports: [
    CamelToTitlePipe,
    SortPipe,
    IsCheckboxColumnPipe,
    IsCheckboxFilterPipe
  ],
  imports: [CommonModule],
})
export class SharedPipesModule {}
